import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout.jsx'

export default function Category ({data, pageContext}) {
  const { posts, categoryName } = pageContext
  return (
    <Layout title={data.site.siteMetadata.title}>
      <div>
      <Link to={'/'}>
          Top
      </Link>
      &ensp; &gt; &ensp; カテゴリ: {`${categoryName}`}
      </div>
        {posts.map(post => {
            return (
              <div key={post.id}>
                <h3>{post.frontmatter.title}</h3>
                <p>{post.frontmatter.date}</p>
                <p>{post.excerpt}</p>
                <Link to={post.frontmatter.path}>
                  <p>続きを読む</p>
                </Link>
              </div>
            )
          })
        }
    </Layout>
  )
}

export const categoryListQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`